<template>
    <div>
        <h1>关于</h1>
    </div>
</template>

<script>
export default {
  name: 'about',
  props: {
    
  }
}
</script>
